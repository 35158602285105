<template>
  <ValidationObserver v-slot="{ validate, invalid }" ref="refValidationObserver">
    <a-form :form="form" class="w-full grid gap-1 form-select" :class="`grid-cols-${cols}`">
      <a-form-item v-for="(item, key) in formItems" :key="key" style="margin-bottom: 0px;" v-if="hide_list.filter(ele=>ele===item.model).length===0">
        <div class=" mb-2" :class="[item.vertically !== true?'flex justify-between':'']">
        <span v-if="showLabel(item)" class="w-full">
          {{ $t(item.model)}}:
          <span v-if="isRequired(item)" class="text-red-500">*</span>
        </span>
          <ValidationProvider :rules="item.validate" v-slot="{ errors }" :name="item.model" class="w-full" >
            <div class="w-full">
              <template v-if="item.type === 'select'">
                  <ant-select
                      :clear_on_change="item.clear_on_change"
                      :client_data_params="item.client_data_params"
                      :use_last_request_params_event="item.use_last_request_params_event"
                      :repeat_request_event="item.repeat_request_event"
                      :clientData="clientData"
                      :disabled="item.read_only === true"
                      :use_router_replace="item.use_router_replace"
                      :filter_field="item.filter_field"
                      v-model="clientData[item.model]"
                      @input="selectHandleChange"
                      :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                      :end_point="item.end_point"
                      :select_list_component="item.select_list_component"
                      :showSearch="true"
                      :use_pagination="true"
                      :name="item.model"/>
              </template>
              <template v-else-if="item.type === 'textarea'">
                  <a-textarea
                      :disabled="item.read_only === true"
                      v-model="clientData[item.model]"
                      :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                      :rows="3"/>
              </template>
              <template v-else-if="item.type === 'checkbox'">
                <checkbox-form-select
                    :disabled="item.read_only === true"
                    :use_router_replace="item.use_router_replace"
                    :name="item.model"
                    :label="$t(item.model)"
                    v-model="clientData[item.model]"
                    @change="change_checkbox($event, item)"/>
<!--                <a-checkbox v-model="clientData[item.model]" @change="change_checkbox($event, item)">-->
<!--                  {{ $t(item.model) }}-->
<!--                </a-checkbox>-->
              </template>
              <template v-else-if="item.type === 'date_time'">
                  <a-date-picker
                      :disabled="item.read_only === true"
                      v-model="clientData[item.model]"
                      show-time
                      :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"/>
              </template>
              <template v-else-if="item.type === 'upload'">
                  <a-upload
                      :disabled="item.read_only === true"
                      list-type="picture"
                      ref="refUpload"
                      name="upload"
                      :action="action"
                      :headers="headers"
                      :default-file-list="clientData[item.model]"
                      :custom-request="customRequest"
                      :before-upload="beforeUpload"
                      @change="handleChange($event, item)">
                    <a-button v-if="item.read_only !== true"> <a-icon type="upload" /> Select File </a-button>
                  </a-upload>
              </template>
              <template v-else-if="item.type === 'map'">
                <div>
                  <div>
                    <div class="w-full mb-4">
                      <a-input v-model="clientData[`${item.model}_value`]" style="width: 100%;" disabled :name="item.model"/>
                    </div>
                    <div class="w-full">
                      <GmapAutocomplete
                          :placeholder="$t('search_address')"
                          class="ant-input"
                          style="width: 100%;"
                          @place_changed="findPlace($event, item.model)"
                      />
                    </div>
                  </div>
                  <GmapMap
                      :center="center"
                      :zoom="12"
                      style="width: 100%; height: 400px;"
                      @click="placeMarker($event, item)"
                      @dblclick="placeMarker($event, item)"
                  >
                    <GmapMarker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        @click="center = m.position"
                    />
                  </GmapMap>
                </div>
              </template>
              <template v-else-if="item.type === 'date_start_end'">
                <date-start-end
                    @change="updated_call"
                    v-model="clientData[item.model]"
                    :use_router_replace="item.use_router_replace"/>
              </template>
              <template v-else>
                  <a-input
                      :disabled="item.read_only === true"
                      v-if="item.mask"
                      v-mask="item.mask"
                      :name="item.model"
                      :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                      v-model="clientData[item.model]"/>
                  <a-input
                      v-else
                      :disabled="item.read_only === true"
                      :name="item.model"
                      :placeholder="item.placeholder ? $t(item.placeholder) : $t(item.model)"
                      v-model="clientData[item.model]"/>

              </template>
            </div>

            <div v-if="errors.length>0">
              <span v-if="errors[0].message === 'number_already_exists'">
                <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ $t('number_already_exists') }}
                  </span>
                  <span class="a-link cursor-pointer" @click="number_already_exists(errors[0])">
                    {{errors[0].data.profile.full_name}}
                  </span>
                </span>
              </span>
              <span v-else-if="errors[0].message === 'IIN_has_already_been'">
                <span class="flex flex-row flex-wrap justify-start" style="line-height: normal;">
                  <span class="text-red-500">
                    {{ $t('number_already_exists') }}
                  </span>
                  <span style="display: contents;">
                    <span class="a-link cursor-pointer mb-2" @click="iin_already_exists(el)" v-for="(el, index) in errors[0].data" :key="index">
                      {{el.profile.full_name}} - {{el.number}}
                      <span v-if="index !== errors[0].data.length - 1">,</span>
                    </span>
                  </span>
                </span>
              </span>
              <span v-else class="text-red-500">
                {{ errors[0] }}
              </span>
            </div>


          </ValidationProvider>
        </div>
      </a-form-item>
    </a-form>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from '@/configs/vee-validate';
import axios from "@/configs/axios";
import AntSelect from "@/components/AntSelect/AntSelect";
import { mask } from 'vue-the-mask'
import EditFiles from "@/crm_components/files/EditFiles";
import GoogleMap from "@/crm_components/GoogleMap/GoogleMap";
import MapMixin from "@/crm_components/GoogleMap/MapMixin";
import DateStartEnd from "@/components/Forms/FormAddClient/DateStartEnd";
import CheckboxFormSelect from "@/components/Forms/FormAddClient/CompsFormSelect/CheckboxFormSelect";
import {EventBus} from "@/configs/eventBus";

export default {
  name: "FormSelect",
  components:{
    CheckboxFormSelect,
    DateStartEnd,
    AntSelect,
    ValidationProvider,
    ValidationObserver,
    EditFiles,
    GoogleMap,
  },
  mixins:[
      MapMixin
  ],
  directives: {
    mask
  },
  data() {
    return {
      clientData: {},
      fileList: [],
      form: this.$form.createForm(this, { name: 'coordinated' }),
      headers:{
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).access,
      },
      hide_list:[]
    };
  },
  props:{
    formItems:{
      type: Array,
      default: []
    },
    cols:{
      type: Number,
      default: 2
    },
    show_label:{
      type: Boolean,
      default: false
    },
    updated_event_bus:{
      type: String,
      default: ''
    }
  },
  computed:{
    action(){
      return process.env.VUE_APP_API_URL + '/common/file/'
    },

  },
  methods: {

    clean_all_key(){
      let clone = {}
      Object.keys(this.clientData).forEach(key => {
        clone[key] = '';
      });
      this.clientData = clone
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
    },
    async customRequest({ file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      formData.append('upload', file);

      try {
        const response = await axios.post(this.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...(file.headers || {})
          },
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          }
        });
        onSuccess(response.data);
      } catch (error) {
        onError(error);
      }
    },
    handleChange(info, item) {
      console.log('info', info)
      console.log('item', item)
      let clone_file_list = info.fileList.map((el)=>{
        console.log(el)
        if (el.response){
          return el.response.uid
        }
        return el.uid
      })
      this.clientData[item.model] = clone_file_list
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    selectHandleChange(val){
      this.clientData[val.name] = val.select
      this.updated_call()
    },
    showLabel(item) {
      return item.show_label === undefined || item.show_label === true;
    },
    isRequired(item) {
      return item.validate && item.validate.includes('required');
    },
    number_already_exists(errors){
      this.$emit('number_already_exists', errors);
      console.log('errors', errors);
    },
    iin_already_exists(errors){
      this.$emit('iin_already_exists', errors);
      console.log('errors', errors);
    },
    async default_change(value, item){
      console.log('name', value)
      console.log('item', item)
      this.clientData[item.model] = await value
      console.log('this.clientData', this.clientData)
    },
    async change_map(name, address){
      console.log('name', name)
      console.log('address', address)
      console.log('this.clientData', this.clientData)
      let clone = {...this.clientData}
      clone[name] = await address
      clone[`${name}_value`] = await address.full_address
      this.clientData = clone
    },
    change_checkbox(e, item){
      console.log('item', item)
      console.log('e', e)

      let clone = {...this.clientData}
      clone[item.model] = e.target.checked;
      this.clientData = clone
      if (item.hide){
        if (item.hide.length>0){
          if (e.target.checked === item.in_case){
            item.hide.forEach((ele)=>{
              this.hide_list.push(ele)
            })
          }else{
            item.hide.forEach((ele)=>{
              this.hide_list = this.hide_list.filter(item=>item!==ele)
            })
          }
        }
      }
      this.updated_call()

    },
    updated_call(){
      console.log('updated_call')
      EventBus.$emit(this.updated_event_bus);
    }
  }
}
</script>

<style lang="scss">
.form-select{
  .ant-form-item-control{
    line-height: 30px;
  }
}
</style>
