<script>
export default {
  name: "MapMixin",
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    async findPlace(place, name) {
      await this.set_place(place.geometry.location.lat(), place.geometry.location.lng(), name);
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        console.log('this.center', this.center)
        if (this.center.lat === 45.508 && this.center.lng === -73.587) {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

        }
      });
    },
    async set_place(lat, lng, name){
      const newMarker = {
        lat: lat,
        lng: lng,
      };
      this.center = { lat, lng };
      let address = await this.getAddress(lat, lng);
      this.markers = [{ position: newMarker }];
      await this.change_map(name, address)
      // await this.$emit('change', this.address);
    },
    async placeMarker(event, item) {
      if (item.read_only !== true){
        await this.set_place(event.latLng.lat(), event.latLng.lng(), item.model);
      }
    },
    async getAddress(lat, lng) {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat, lng };
      return new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              let country = '',
                  city = '',
                  street = '',
                  house = '';
              for (let i = 0; i < results[0].address_components.length; i++) {
                let addr = results[0].address_components[i];
                if (addr.types.includes('country')) country = addr.long_name;
                else if (addr.types.includes('locality')) city = addr.long_name;
                else if (addr.types.includes('route')) street = addr.long_name;
                else if (addr.types.includes('street_number')) house = addr.long_name;
              }
              resolve({
                city: city,
                country: country,
                street: street,
                house: house,
                longitude: lng,
                latitude: lat,
                full_address: `${country}, ${city}, ${street}, ${house}`,
              });
            } else {
              console.log('No results found');
              resolve(null);
            }
          } else {
            console.log(`Geocoder failed due to: ${status}`);
            resolve(null);
          }
        });
      });
    },
  },
}
</script>

<style scoped>

</style>
